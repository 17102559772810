import * as api from '../api';
import { PURGE } from 'redux-persist';

const GET_ALL_RIDES = 'rides/GET_ALL_RIDES';
const GET_RIDE_LOCATIONS = 'rides/GET_RIDE_LOCATIONS';
const GET_RIDES_LOCATIONS = 'rides/GET_RIDES_LOCATIONS';
const GET_REFUND_INFO = 'rides/GET_REFUND_INFO';
const END_RIDE = 'rides/END_RIDE';
const REFUND_RIDE = 'rides/REFUND_RIDE';
const REFUND_PARTIAL_RIDE = 'rides/REFUND_PARTIAL_RIDE';
const GET_RIDES_IN_ZONE = 'rides/GET_RIDES_IN_ZONE';
const GET_RIDE_BY_ID = 'rides/GET_RIDE_BY_ID';
const CLEAR_RIDES = 'rides/CLEAR_RIDES';
const initialState = [];

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_RIDES:
      return action.data.map((ride) => {
        // Reformat the properties that aren't returned from the database in a very user-friendly format
        // Because of the large number of rows returned, it's more efficient to just modify the data right
        // after it's returned so that it's ready for both display and download as CSV. If we ever want to
        // do fancy analytics or anything, this will need to be rethought.
        const { start_location, end_location, ...rest } = ride;
        if (!Array.isArray(start_location)) {
          rest.start_location = [start_location.latitude, start_location.longitude];
        } else {
          rest.start_location = start_location;
        }
        if (end_location && !Array.isArray(end_location)) {
          rest.end_location = [end_location.latitude, end_location.longitude];
        } else {
          rest.end_location = end_location;
        }
        return rest;
      });
    case GET_RIDE_LOCATIONS:
      return {
        ...state,
        locations: action.data,
      };
    case GET_REFUND_INFO:
      return {
        ...state,
        refund: action.data,
      };
    case GET_RIDES_IN_ZONE:
      return action.data;
    case GET_RIDE_BY_ID:
      return {
        ...state,
        ride: action.data,
      };
    case PURGE:
      return [];
    case END_RIDE:
      return {
        ...state,
        ride: action,
      };
    case REFUND_RIDE:
      return state;
    case REFUND_PARTIAL_RIDE:
      return state;
    case CLEAR_RIDES:
      return [];
    default:
      return state;
  }
}

export const getRides = () => {
  return (dispatch) => {
    return api
      .getRides()
      .then((rides) => {
        if (rides) {
          dispatch({ type: GET_ALL_RIDES, data: rides });
        } else {
          throw new Error('There was a problem trying to get rides');
        }
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const getRidesLocations = (start, end) => {
  return (dispatch) => {
    return api
      .getRidesLocations(start, end)
      .then((locations) => {
        if (locations) {
          dispatch({ type: GET_RIDES_LOCATIONS, data: locations });
        } else {
          throw new Error('There was a problem trying to get rides');
        }
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const getRideLocations = (ride_id) => {
  return (dispatch) => {
    return api
      .getRideLocations(ride_id)
      .then((locations) => {
        if (locations) {
          dispatch({ type: GET_RIDE_LOCATIONS, data: locations });
        } else {
          throw new Error('There was a problem trying to get rides');
        }
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const getRefundForRide = (ride_id) => {
  return (dispatch) => {
    return api
      .getRefundForRide(ride_id)
      .then((response) => {
        if (response) {
          dispatch({ type: GET_REFUND_INFO, data: response });
        } else {
          throw new Error('There was a problem trying to get refund info');
        }
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const endRide = (rideId, params) => {
  return (dispatch) => {
    return api
      .endRide(rideId, params)
      .then((response) => {
        if (response) {
          dispatch({ type: END_RIDE, data: null });
        } else {
          throw new Error('There was a problem trying to end ride');
        }
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const refundRide = (user_id, params) => {
  return (dispatch) => {
    return api
      .refundRide(user_id, params)
      .then((response) => {
        if (response) {
          dispatch({ type: REFUND_RIDE, data: null });
        } else {
          throw new Error('There was a problem refunding user');
        }
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const refundPartialRide = (user_id, params) => {
  return (dispatch) => {
    return api
      .refundPartialRide(user_id, params)
      .then((response) => {
        if (response) {
          dispatch({ type: REFUND_PARTIAL_RIDE, data: null });
        } else {
          throw new Error('There was a problem refunding user');
        }
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const getRidesInZone = (zone_id, params) => {
  return (dispatch) => {
    return api
      .getRidesInZone(zone_id, params)
      .then((response) => {
        if (response) {
          dispatch({ type: GET_RIDES_IN_ZONE, data: response });
        } else {
          throw new Error('There was a problem getting rides in zone');
        }
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const getRideById = (params) => {
  return (dispatch) => {
    return api
      .getRideById(params)
      .then((response) => {
        if (response) {
          const response_array = [];
          response_array.push(response);
          dispatch({ type: GET_RIDE_BY_ID, data: response_array });
        } else {
          throw new Error('There was a problem getting ride by Id');
        }
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const clearRides = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_RIDES });
  };
};
