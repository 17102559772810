import * as api from '../api';
import { PURGE } from 'redux-persist';

const GET_RIDES_IN_ZONE = 'zones/GET_RIDES_IN_ZONE';

const initialState = { routes: [] };

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_RIDES_IN_ZONE:
      return action.data;
    case PURGE:
      return initialState;
    default:
      return state;
  }
}

export const getRidesInZone = (zone_id, params) => {
  return (dispatch) => {
    return api.getRidesInZone(zone_id, params).then((rides) => {
      dispatch({ type: GET_RIDES_IN_ZONE, data: rides });
    });
  };
};
