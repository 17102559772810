import * as api from '../api';
import { PURGE } from 'redux-persist';

const GET_RESTRICTED_AREAS = 'restrictedAreas/GET_RESTRICTED_AREAS';
const GET_RESTRICTED_AREAS_WITH_PARAMS = 'zones/GET_RESTRICTED_AREAS_WITH_PARAMS';
const CREATE_RESTRICTED_AREA = 'zones/CREATE_RESTRICTED_AREA';
const DELETE_RESTRICTED_AREA = 'zones/DELETE_RESTRICTED_AREA';
const PATCH_RESTRICTED_AREA = 'zones/PATCH_RESTRICTED_AREA';

const initialState = { restrictedAreas: [], selectedRestrictedAreas: [] };

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_RESTRICTED_AREAS:
      return Object.assign({}, { ...state }, { restrictedAreas: action.data });
    case CREATE_RESTRICTED_AREA:
      return state;
    case DELETE_RESTRICTED_AREA:
      return state;
    case PATCH_RESTRICTED_AREA:
      return state;
    case GET_RESTRICTED_AREAS_WITH_PARAMS:
      return Object.assign({}, { ...state }, { selectedRestrictedAreas: action.data });
    case PURGE:
      return initialState;
    default:
      return state;
  }
}

export const getRestrictedAreas = (zone_id) => {
  return (dispatch) => {
    return api
      .getRestrictedAreas(zone_id)
      .then(({ restricted_areas: restrictedAreas }) => {
        if (restrictedAreas) {
          dispatch({ type: GET_RESTRICTED_AREAS, data: restrictedAreas });
        } else {
          throw new Error('There was a problem trying to get zone data');
        }
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const getRestrictedAreasWithParams = (zone_id, params) => {
  return (dispatch) => {
    return api
      .getRestrictedAreasWithParams(zone_id, params)
      .then(({ restricted_areas: restrictedAreas }) => {
        if (restrictedAreas) {
          dispatch({ type: GET_RESTRICTED_AREAS_WITH_PARAMS, data: restrictedAreas });
        } else {
          throw new Error('There was a problem trying to get restricted area data');
        }
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const createRestrictedArea = (zone_id, params) => {
  return (dispatch) => {
    return api
      .createRestrictedArea(zone_id, params)
      .then((restrictedArea) => {
        if (restrictedArea) {
          dispatch({ type: CREATE_RESTRICTED_AREA, data: null });
        } else {
          throw new Error('There was a problem trying to get zone data');
        }
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const deleteRestrictedArea = (zone_id, restricted_area_id) => {
  return (dispatch) => {
    return api
      .deleteRestrictedArea(zone_id, restricted_area_id)
      .then((restrictedArea) => {
        if (restrictedArea) {
          dispatch({ type: DELETE_RESTRICTED_AREA, data: null });
        } else {
          throw new Error('There was a problem trying to get zone data');
        }
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const patchRestrictedArea = (zone_id, restricted_area_id, params) => {
  return (dispatch) => {
    return api
      .patchRestrictedArea(zone_id, restricted_area_id, params)
      .then((restrictedArea) => {
        if (restrictedArea) {
          dispatch({ type: PATCH_RESTRICTED_AREA, data: null });
        } else {
          throw new Error('There was a problem trying to patch restricted area');
        }
      })
      .catch((error) => {
        throw error;
      });
  };
};
