import * as api from '../api';
import { PURGE } from 'redux-persist';

const GET_OPS_LOCATIONS = 'ops_locations/GET_OPS_LOCATIONS';

const initialState = { ops_user_locations: [] };

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_OPS_LOCATIONS:
      return action.data;
    case PURGE:
      return [];
    default:
      return state;
  }
}

export const getOpsLocations = (zone_id, params) => {
  return (dispatch) => {
    return api.getOpsLocations(zone_id, params).then((ops_locations) => {
      dispatch({ type: GET_OPS_LOCATIONS, data: ops_locations });
    });
  };
};
