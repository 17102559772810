import wrapWithProvider from './src/wrap-with-provider';
export const wrapRootElement = wrapWithProvider;
import './src/styles/App.css';
import './src/styles/index.scss';

export const onClientEntry = () => {
  String.prototype.sanitizeNonAlphaNumerics = function () {
    const regex = /[^A-Za-z0-9]/g;
    return this.replace(regex, '');
  };
};
