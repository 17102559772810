import * as api from '../api';

const GET_REFUNDS = 'routes/GET_REFUNDS';

const initialState = [];

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_REFUNDS:
      return action.data;
    default:
      return state;
  }
}

export const getRefunds = (params) => {
  return (dispatch) => {
    return api
      .getRefunds(params)
      .then((refunds) => dispatch({ type: GET_REFUNDS, data: refunds }))
      .catch(() => {
        throw new Error('Get Refunds failed');
      });
  };
};
