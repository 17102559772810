import * as api from '../api';

const CREATE_PARKING_SPOT = 'parkingSpots/CREATE_PARKING_SPOT';
const PATCH_PARKING_SPOT = 'zones/PATCH_PARKING_SPOT';
const DELETE_PARKING_SPOT = 'zones/DELETE_PARKING_SPOT';

const initialState = [];

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_PARKING_SPOT:
      return action.data;
    case PATCH_PARKING_SPOT:
      return state;
    case DELETE_PARKING_SPOT:
      return state;
    default:
      return state;
  }
}

export const createParkingSpot = (zone_id, params) => {
  return (dispatch) => {
    return api
      .createParkingSpot(zone_id, params)
      .then((response) => dispatch({ type: CREATE_PARKING_SPOT, data: response }))
      .catch(() => {
        throw new Error('Create Parking Spot Failed');
      });
  };
};

export const patchParkingSpot = (zone_id, parking_spot_id, params) => {
  return (dispatch) => {
    return api
      .patchParkingSpot(zone_id, parking_spot_id, params)
      .then((parkingSpot) => {
        if (parkingSpot) {
          dispatch({ type: PATCH_PARKING_SPOT, data: null });
        } else {
          throw new Error('There was a problem trying to patch restricted area');
        }
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const deleteParkingSpot = (zone_id, parking_spot_id) => {
  return (dispatch) => {
    return api
      .deleteParkingSpot(zone_id, parking_spot_id)
      .then(() => dispatch({ type: DELETE_PARKING_SPOT, data: null }))
      .catch((error) => {
        throw error;
      });
  };
};
