import * as api from '../api';

const GET_RIDE_PHOTO = 'routes/GET_RIDE_PHOTO';
const SEND_REVIEW = 'routes/SEND_REVIEW';

const initialState = [];

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_RIDE_PHOTO:
      return action.data;
    case SEND_REVIEW:
      return state;
    default:
      return state;
  }
}

export const getRidePhoto = (ride_id, photo_id) => {
  return (dispatch) => {
    return api
      .getRidePhoto(ride_id, photo_id)
      .then((route) => dispatch({ type: GET_RIDE_PHOTO, data: route }))
      .catch((error) => {
        throw new Error(error.message);
      });
  };
};

export const sendPhotoReview = (ride_id, photo_id, params) => {
  return (dispatch) => {
    return api
      .sendPhotoReview(ride_id, photo_id, params)
      .then(() => dispatch({ type: SEND_REVIEW, data: null }))
      .catch((error) => {
        throw error;
      });
  };
};
