import * as api from '../api';
import { PURGE } from 'redux-persist';

const GET_OPS_USERS = 'users/GET_OPS_USERS';
const GET_ROUTED_OPS = 'users/GET_ROUTED_OPS';

const initialState = {
  ops: [],
  routed_ops: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case PURGE:
      return initialState;
    case GET_OPS_USERS:
      return {
        ...state,
        ops: action.data.ops_employees.map((user) => {
          const fullname = user.first_name + ' ' + user.last_name;
          return {
            id: user.id,
            fullname: fullname,
            is_active: user.is_active,
          };
        }),
      };
    case GET_ROUTED_OPS:
      return {
        ...state,
        routed_ops: action.data,
      };
    default:
      return state;
  }
}

export const getRoutedOps = (zone_id) => {
  return (dispatch) => {
    return api
      .getRoutedOps(zone_id)
      .then((ops) => {
        if (ops) {
          dispatch({ type: GET_ROUTED_OPS, data: ops });
        } else {
          throw new Error('There was a problem trying to get routed ops');
        }
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const getOpsUsers = (zone_id) => {
  return (dispatch) => {
    return api
      .getOpsUsers(zone_id)
      .then((users) => dispatch({ type: GET_OPS_USERS, data: users }))
      .catch((error) => {
        throw error;
      });
  };
};
