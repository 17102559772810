import * as api from '../api';

const GET_ROUTES = 'routes/GET_ROUTES';
const GET_ROUTE = 'routes/GET_ROUTE';
const GET_MANUAL_ROUTE = 'routes/GET_MANUAL_ROUTE'
const CREATE_FIELD_TICKETS = 'routes/CREATE_FIELD_TICKETS';
const CREATE_SCHEDULED_ROUTE = 'routes/CREATE_SCHEDULED_ROUTE';
const GET_ROUTE_VEHICLES = 'routes/GET_ROUTE_VEHICLES';


const initialState = [];

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_ROUTES:
      return action.data;
    case GET_ROUTE:
      return action.data;
    case GET_ROUTE_VEHICLES:
      return action.data;
    case GET_MANUAL_ROUTE:
      return action.data;
    case CREATE_FIELD_TICKETS:
      return action.data;
    case CREATE_SCHEDULED_ROUTE:
      return state;
    default:
      return state;
  }
}

export const getRoutes = params => {
  return dispatch => {
    return api.getRoutes(params)
    .then(routes => {
      if (routes.message && routes.message === 'exceeds_limit') {
        if (window.confirm('Routes cannot exceed 300 vehicles. This route has ' + routes.route_length + ' vehicles on it')) {
          window.location.reload();
        } else {
          window.location.reload();
        }
      }
      if (routes.message && routes.message === 'no_vehicles') {
        if (window.confirm('Insufficient route: Routes must contain at least 2 vehicles.')) {
          window.location.reload();
        } else {
          window.location.reload();
        }
      }
      dispatch({ type: GET_ROUTES, data: routes });
    })
    .catch(error => {
      alert(error.message);
      throw new Error('Get Routes failed');
    });
  };
};


export const getManualRoute = params => {
  return dispatch => {
    return api.getManualRoute(params)
    .then(routes => {
      if (routes.message && routes.message === 'exceeds_limit') {
        if (window.confirm('Routes cannot exceed 300 vehicles. This route has ' + routes.route_length + ' vehicles on it')) {
          window.location.reload();
        } else {
          window.location.reload();
        }
      }
      if (routes.message && routes.message === 'no_vehicles') {
        if (window.confirm('Insufficient route: Routes must contain at least 2 vehicles.')) {
          window.location.reload();
        } else {
          window.location.reload();
        }
      }
      dispatch({ type: GET_MANUAL_ROUTE, data: routes });
    })
    .catch(error => {
      alert(error.message);
      throw new Error('Get Routes failed');
    });
  };
};


export const createFieldTickets = (params) => {
  return dispatch => {
    return api
      .createFieldTickets(params)
      .then(routeResp => {
        if (routeResp) {
          dispatch({ type: CREATE_FIELD_TICKETS, data: routeResp  });
        }
      })
      .catch(error => {
        throw error;
      });
  };
};

export const createScheduledRoute = (params) => {
  return dispatch => {
    return api
      .createScheduledRoute(params)
      .then(routeResp => {
        if (routeResp) {
          dispatch({ type: CREATE_SCHEDULED_ROUTE, data: ''  });
        }
      })
      .catch(error => {
        throw error;
      });
  };
};

export const getRoute = route_id => {
  return dispatch => {
    return api.getRoute(route_id)
    .then(route => {
      dispatch({ type: GET_ROUTE, data: route });
    })
    .catch(error => {
      alert(error.message);
      throw new Error('Get Route failed');
    });
  };
};

export const getRouteVehicles = params => {
  return dispatch => {
    return api.getRouteVehicles(params)
    .then(route => {
      dispatch({ type: GET_ROUTE_VEHICLES, data: route });
    })
    .catch(error => {
      alert(error.message);
      throw new Error('Get Route Vehicles failed');
    });
  };
};
