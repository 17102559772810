import * as api from '../api';
import { PURGE } from 'redux-persist';

const CLEAR_USER = 'user/CLEAR_USER';
const GET_USER = 'user/GET_USER';
const PATCH_USER = 'user/PATCH_USER';
const GET_USER_RIDES = 'user/GET_USER_RIDES';
const initialState = null;

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_USER: {
      const [user] = action.data;
      user.rides = state ? state.rides : [];
      return user || state;
    }
    case GET_USER_RIDES:
      return {
        ...state,
        rides: action.data,
      };
    case PATCH_USER:
      return {
        ...state,
        balance_owing: action.data.balance_owing,
        pricing_plan: action.data.pricing_plan,
        free_rides: action.data.free_rides,
        is_active: action.data.is_active,
      };
    case PURGE:
    case CLEAR_USER:
      return initialState;
    default:
      return state;
  }
}

export const getUser = (params) => {
  return (dispatch) => {
    return api
      .getUsers(params)
      .then((user) => {
        if (user.length > 0) {
          dispatch({ type: GET_USER, data: user });
        } else {
          throw new Error('There was a problem trying to get the user');
        }
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const patchUser = (id, params) => {
  return (dispatch) => {
    return api
      .patchUser(id, params)
      .then((user) => {
        if (user) {
          dispatch({ type: PATCH_USER, data: user });
        } else {
          throw new Error('There was a problem trying to update the user');
        }
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const getRidesForUser = (user_id) => {
  return (dispatch) => {
    return api
      .getRides(user_id)
      .then((rides) => {
        if (rides) {
          dispatch({ type: GET_USER_RIDES, data: rides });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const clearUser = () => {
  return (dispatch) => dispatch({ type: CLEAR_USER, data: null });
};
