import React from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';

import createStore from './createStore';

import { getZones } from './api';

const { store, persistor } = createStore();

const onBeforeLift = async () => {
  await getZones().then((zoneData) => {
    store.dispatch({ type: 'zones/GET_ALL_ZONES', data: zoneData });
  });
};

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => {
  return (
    <Provider store={store}>
      <PersistGate
        loading={null}
        persistor={persistor}
        onBeforeLift={async () => await onBeforeLift()}>
        {element}
      </PersistGate>
    </Provider>
  );
};
