const SET_SELECTED_ZONE = 'selected_zone/SET_SELECTED_ZONE';
const RESET_SELECTED_ZONE = 'selected_zone/RESET_SELECTED_ZONE';

const initialState = null;

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_SELECTED_ZONE:
      return action.data;
    case RESET_SELECTED_ZONE:
      return initialState;
    default:
      return state;
  }
}

export const setSelectedZone = (zone_id) => {
  return (dispatch) => {
    dispatch({ type: SET_SELECTED_ZONE, data: zone_id });
  };
};

export const resetSelectedZone = () => {
  return (dispatch) => {
    dispatch({ type: RESET_SELECTED_ZONE });
  };
};
