import * as api from '../api';
import { PURGE } from 'redux-persist';

const GET_ALL_ZONES = 'zones/GET_ALL_ZONES';
const GET_RESTRICTED_AREAS = 'zones/GET_RESTRICTED_AREAS';
const GET_FEATURES = 'zones/GET_FEATURES';
const GET_PARKING_SPOT = 'zones/GET_PARKING_SPOT';
const GET_DEPLOYMENT_SPOTS = 'zones/GET_DEPLOYMENT_SPOTS';
const DELETE_FIELD_ROUTES = 'zones/DELETE_FIELD_ROUTES';
const SET_SELECTED_ZONE = 'zones/SET_SELECTED_ZONE';

const initialState = {
  deployment_spots: { deploymentSpots: [], deploymentAreas: [] },
  features: { parkingAreas: [], parkingSpots: [], restrictedAreas: [] },
  zones: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_ZONES:
      return Object.assign({}, { ...state }, { zones: action.data });
    case GET_RESTRICTED_AREAS:
      return Object.assign({}, { ...state }, { restricted_areas: action.data });
    case GET_FEATURES:
      return {
        ...state,
        features: action.data,
      };
    case GET_PARKING_SPOT:
      return {
        ...state,
        parking_spot: action.data,
      };
    case GET_DEPLOYMENT_SPOTS:
      return {
        ...state,
        deployment_spots: action.data,
      };
    case DELETE_FIELD_ROUTES:
      return state;
    case SET_SELECTED_ZONE:
      return {
        ...state,
        selectedZone: action.data,
      };
    case PURGE:
      return initialState;
    default:
      return state;
  }
}

export const getAllZones = () => {
  return (dispatch) => {
    return api
      .getZones()
      .then((zones) => {
        if (zones) {
          dispatch({ type: GET_ALL_ZONES, data: zones });
        } else {
          throw new Error('There was a problem trying to get zone data');
        }
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const deleteFieldRoutes = (zone_id) => {
  return (dispatch) => {
    return api
      .deleteFieldRoutes(zone_id)
      .then((response) => {
        if (response) {
          dispatch({ type: DELETE_FIELD_ROUTES });
        } else {
          throw new Error('There was a problem deleting field tickets for route');
        }
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const getRestrictedAreas = (zone_id) => {
  return (dispatch) => {
    return api
      .getRestrictedAreas(zone_id)
      .then((restrictedAreas) => {
        if (restrictedAreas) {
          dispatch({ type: GET_RESTRICTED_AREAS, data: restrictedAreas });
        } else {
          throw new Error('There was a problem trying to get zone data');
        }
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const getFeatures = (zone_id, params) => {
  return (dispatch) => {
    return api
      .getFeatures(zone_id, params)
      .then((features) => {
        if (features) {
          dispatch({ type: GET_FEATURES, data: features });
        } else {
          throw new Error('There was a problem trying to get zone feature data');
        }
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const getDeploymentSpots = (zone_id) => {
  return (dispatch) => {
    return api
      .getDeploymentSpots(zone_id)
      .then((deployment_spots) => {
        if (deployment_spots) {
          dispatch({ type: GET_DEPLOYMENT_SPOTS, data: deployment_spots });
        } else {
          throw new Error('There was a problem trying to get zone deployment spots data');
        }
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const getParkingSpot = (zone_id, spot_id) => {
  return (dispatch) => {
    return api
      .getParkingSpot(zone_id, spot_id)
      .then((parking_spots) => {
        if (parking_spots) {
          dispatch({ type: GET_PARKING_SPOT, data: parking_spots });
        } else {
          throw new Error('There was a problem trying to get parking spot data');
        }
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const setSelectedZone = (zone_id) => {
  return (dispatch) => {
    dispatch({ type: SET_SELECTED_ZONE, data: zone_id });
  };
};
