import * as api from '../api';
import { PURGE } from 'redux-persist';

const GET_VEHICLES = 'vehicles/GET_ALL_VEHICLES';
const GET_VEHICLES_IN_POLYGON = 'vehicles/GET_VEHICLES_IN_POLYGON';
const GET_VEHICLES_IN_AREA = 'vehicles/GET_VEHICLES_IN_AREA';

const initialState = { vehicles: [], selected: [] };

export default function reducer(state = initialState, action) {
  let existingVehicleNames = [];
  let vehiclesToAdd = [];
  switch (action.type) {
    case GET_VEHICLES:
      return {
        ...state,
        vehicles: action.data,
      };
    case GET_VEHICLES_IN_AREA:
    case GET_VEHICLES_IN_POLYGON:
      existingVehicleNames = state.selected.map(({ name }) => name);
      vehiclesToAdd = action.data.filter(({ name }) => !existingVehicleNames.includes(name));
      return {
        ...state,
        selected: state.selected.concat(vehiclesToAdd),
      };
    case PURGE:
      return initialState;
    default:
      return state;
  }
}

export const getZoneVehicles = (zone_id, params) => {
  console.log({ zone_id, params });
  return (dispatch) => {
    return api
      .getZoneVehicles(zone_id, params)
      .then((vehicles) => {
        if (vehicles) {
          dispatch({ type: GET_VEHICLES, data: vehicles });
        } else {
          throw new Error('There was a problem trying to get vehicles data');
        }
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const getVehiclesInPolygon = (zone_id, params) => {
  return (dispatch) => {
    return api
      .getVehiclesInPolygon(zone_id, params)
      .then((vehicles) => {
        if (vehicles) {
          dispatch({ type: GET_VEHICLES_IN_POLYGON, data: vehicles });
        } else {
          throw new Error('There was a problem trying to get vehicles data');
        }
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const getVehiclesInArea = (zone_id, params) => {
  return (dispatch) => {
    return api
      .getVehiclesInArea(zone_id, params)
      .then((vehicles) => {
        if (vehicles) {
          dispatch({ type: GET_VEHICLES_IN_AREA, data: vehicles });
        } else {
          throw new Error('There was a problem trying to get vehicles data');
        }
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const getFleetVehicles = (fleet_id) => {
  return (dispatch) => {
    return api
      .getFleetVehicles(fleet_id)
      .then((vehicles) => {
        if (vehicles) {
          dispatch({ type: GET_VEHICLES, data: vehicles });
        } else {
          throw new Error('There was a problem trying to get vehicles data');
        }
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const getAllVehicles = () => {
  return (dispatch) => {
    return api.getAllVehicles().then((vehicles) => {
      dispatch({ type: GET_VEHICLES, data: vehicles });
    });
  };
};
