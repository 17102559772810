import * as api from '../api';
import { PURGE } from 'redux-persist';

const GET_COMM_TYPES = 'vehicles/GET_COMM_TYPES';

const initialState = [];

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_COMM_TYPES:
      return action.data;
    case PURGE:
      return [];
    default:
      return state;
  }
}

export const getCommTypes = () => {
  return dispatch => {
    return api.getCommTypes().then(types => {
      dispatch({ type: GET_COMM_TYPES, data: types });
    });
  };
};
