import * as api from '../api';

const GET_FIELD_ROUTE_STATUSES = 'routes/GET_FIELD_ROUTE_STATUSES';

const initialState = [];

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_FIELD_ROUTE_STATUSES:
     // return action.data;
      return action.data;
    default:
      return state;
  }

}

export const getFieldRouteStatuses = route_id => {
  return dispatch => {
    return api.getFieldRouteStatuses(route_id)
    .then(routeStatuses => {
      dispatch({ type: GET_FIELD_ROUTE_STATUSES, data: routeStatuses });
    })
    .catch(error => {
      alert(error.message)
      throw new Error('Get Field Route statuses failed');
    });
  };
};
