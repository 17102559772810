import * as api from '../api';
import { PURGE } from 'redux-persist';

const GET_VEHICLE_STATUSES = 'vehicles/GET_VEHICLE_STATUSES';

const initialState = [];

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_VEHICLE_STATUSES:
      return action.data.filter(
        (status) => status !== 'warehouse_charged' && status !== 'warehouse_noncharging'
      );
    case PURGE:
      return [];
    default:
      return state;
  }
}

export const getVehicleStatuses = () => {
  return (dispatch) => {
    return api.getVehicleStatuses().then((statuses) => {
      dispatch({ type: GET_VEHICLE_STATUSES, data: statuses });
    });
  };
};
