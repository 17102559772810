import * as api from '../api';
import { PURGE } from 'redux-persist';

const GET_SUBZONE = 'subzones/GET_SUBZONE';

const initialState = { subzones: [] };

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_SUBZONE:
      return action.data;
    case PURGE:
      return initialState;
    default:
      return state;
  }
}

export const getSubzones = (zone_id) => {
  return (dispatch) => {
    return api.getSubzones(zone_id).then((subzones) => {
      dispatch({ type: GET_SUBZONE, data: subzones });
    });
  };
};
