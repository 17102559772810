const { GATSBY_REACT_APP_API_HOST: API_HOST } = process.env;

const API_HOST_V1 = `${API_HOST}/api/v1`;
const API_HOST_V2 = `${API_HOST}/api/v2`;
const API_HOST_V3 = `${API_HOST}/api/v3`;

export const login = (email, password) => {
  const url = `${API_HOST_V2}/admins/login`;
  const body = {
    email,
    password,
  };
  return fetch(url, {
    credentials: 'include',
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/JSON',
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      switch (response.status) {
        case 404:
          throw new Error('User not found');
        case 401:
          throw new Error('Incorrect username or password');
        default:
          throw new Error('There was a problem with your request. Please try again later');
      }
    }
  });
};

export const me = () => {
  const url = `${API_HOST_V2}/me`;
  return fetch(url, {
    credentials: 'include',
    method: 'GET',
  }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw new Error({ code: response.status });
    }
  });
};

export const requestPasswordReset = (email) => {
  const url = `${API_HOST_V2}/users/request-password-reset`;
  const body = { email, resetVersion: '2.0' };
  return fetch(url, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/JSON',
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.json().then((json) => {
        throw json;
      });
    }
  });
};

export const resetPassword = (userId, token, password) => {
  const url = `${API_HOST_V2}/users/reset-password`;
  const body = {
    id: userId,
    token,
    password,
  };
  return fetch(url, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/JSON',
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.json().then((json) => {
        throw json;
      });
    }
  });
};

export const updatePassword = (id, oldPassword, newPassword) => {
  const url = `${API_HOST_V2}/admins/${id}/password`;
  const body = {
    password: oldPassword,
    new_password: newPassword,
  };
  return fetch(url, {
    method: 'PATCH',
    body: JSON.stringify(body),
    credentials: 'include',
    headers: {
      'Content-Type': 'application/JSON',
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.json().then((json) => {
        throw json;
      });
    }
  });
};

export const logout = () => {
  const url = `${API_HOST_V2}/admins/logout`;
  return fetch(url, {
    method: 'POST',
    credentials: 'include',
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((json) => {
      console.log(json);
    });
};

export const createOpsUser = (params) => {
  const url = `${API_HOST_V1}/ops-signup`;
  return fetch(url, {
    credentials: 'include',
    method: 'POST',
    body: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/JSON',
    },
  }).then((response) => response.json());
};

export const getUsers = (params) => {
  var queryString = Object.keys(params)
    .map((key) => key + '=' + params[key])
    .join('&');
  const url = `${API_HOST_V2}/users?${queryString}`;
  return fetch(url, { credentials: 'include' })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('There was a problem with the request.');
      }
    })
    .then((json) => {
      return json;
    });
};

export const patchUser = (user_id, params) => {
  const url = `${API_HOST_V2}/users/${user_id}`;
  return fetch(url, {
    credentials: 'include',
    method: 'PATCH',
    body: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/JSON',
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw new Error('There was a problem trying to update the user');
    }
  });
};

export const endRide = (rideId, params) => {
  const url = `${API_HOST_V2}/rides/${rideId}/stop`;
  return fetch(url, {
    credentials: 'include',
    method: 'POST',
    body: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/JSON',
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  });
};

export const refundRide = (ride_id, params) => {
  const url = `${API_HOST_V2}/rides/${ride_id}/refund`;
  return fetch(url, {
    credentials: 'include',
    method: 'POST',
    body: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/JSON',
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  });
};

export const refundPartialRide = (ride_id, params) => {
  const url = `${API_HOST_V2}/rides/${ride_id}/refund-partial`;
  return fetch(url, {
    credentials: 'include',
    method: 'POST',
    body: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/JSON',
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  });
};

export const getRefundForRide = (ride_id) => {
  const path = `/rides/${ride_id}/get-ride-refund`;
  const url = `${API_HOST_V2}${path}`;
  return fetch(url, { credentials: 'include' })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
};

export const getZones = () => {
  const url = `${API_HOST_V2}/zones`;
  return fetch(url, { credentials: 'include' })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
};

/* Create a new warehouse area on the map to omit scooters
 * - Pass in redundend zone_id for now, will be added to security check later!
 */
export const createWarehouse = (zone_id, params) => {
  const url = `${API_HOST_V2}/admins/warehouse-areas`;
  return fetch(url, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/JSON',
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.json().then((json) => {
        throw json;
      });
    }
  });
};

//Unimplemented! Do not use
export const _patchWarehouse = (zone_id, restricted_area_id, params) => {
  const url = `${API_HOST_V2}/zones/${zone_id}/restricted-areas/${restricted_area_id}`;
  return fetch(url, {
    method: 'PATCH',
    credentials: 'include',
    body: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/JSON',
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.json().then((json) => {
        throw json;
      });
    }
  });
};

//Unimplemented! Do not use
export const _deleteWarehouse = (zone_id, restricted_area_id) => {
  const url = `${API_HOST_V2}/zones/${zone_id}/restricted-areas/${restricted_area_id}`;
  return fetch(url, {
    method: 'DELETE',
    credentials: 'include',
  }).then((response) => {
    return response.json();
  });
};


export const getWarehouse = (zone_id) => {
  const url = `${API_HOST_V2}/zones/${zone_id}/warehouse`;
  return fetch(url, { credentials: 'include' })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
};

export const getRides = (user_id) => {
  const path = user_id ? `/users/${user_id}/rides` : '/rides';
  const url = `${API_HOST_V2}${path}`;
  return fetch(url, { credentials: 'include' })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
};

export const getRidesById = (rideIds) => {
  const path = `${API_HOST_V2}/rides`;
  const queryParams = rideIds.map((rideId) => `ride_ids[]=${rideId}`).join('&');
  const url = `${path}?${queryParams}`;
  return fetch(url, { credentials: 'include' })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
};

export const getAllVehicles = () => {
  const url = `${API_HOST_V2}/vehicles`;
  return fetch(url, { credentials: 'include' })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
};

export const getFleetVehicles = (fleet_id) => {
  const url = `${API_HOST_V2}/fleets/${fleet_id}/vehicles`;
  return fetch(url, { credentials: 'include' })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
};

export const getZoneVehicles = (zone_id, params) => {
  var queryString = Object.keys(params)
    .map((key) => {
      let statuses;
      if (key === 'statuses') {
        statuses = params[key]
          .map((val) => {
            const status = 'statuses[]=' + val;
            return status;
          })
          .join('&');
        return statuses;
      } else {
        const query_val = key + '=' + params[key];
        return query_val;
      }
    })
    .join('&');
  const url = `${API_HOST_V2}/zones/${zone_id}/vehicles?${queryString}`;
  return fetch(url, { credentials: 'include' })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
};

export const statusChangeCSVDownloadLink = () => {
  return `${API_HOST_V2}/vehicles/charging-status-changes`;
};

export const addVehiclesFromCSV = (formData) => {
  const url = `${API_HOST_V2}/vehicles`;
  return fetch(url, {
    method: 'POST',
    body: formData,
  }).then((response) => {
    if (response.ok) {
      return response.blob();
    } else {
      return response.json().then((json) => {
        throw new Error(json.message || 'There was a problem with your request');
      });
    }
  });
};

export const getVehicleStatuses = () => {
  const url = `${API_HOST_V2}/vehicles/statuses`;
  return fetch(url).then((response) => {
    if (response.ok) {
      return response.json();
    }
  });
};

export const getVehicleTypes = () => {
  const url = `${API_HOST_V2}/vehicles/types`;
  return fetch(url).then((response) => {
    if (response.ok) {
      return response.json();
    }
  });
};

export const getBatteryTypes = () => {
  const url = `${API_HOST_V2}/vehicles/battery-types`;
  return fetch(url).then((response) => {
    if (response.ok) {
      return response.json();
    }
  });
};

export const getCommTypes = () => {
  const url = `${API_HOST_V2}/comm-types`;
  return fetch(url, { credentials: 'include' })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .catch((error) => console.error(error));
};

export const getVehicleByName = (name) => {
  const url = `${API_HOST_V2}/vehicles?name=${name}`;
  return fetch(url, { credentials: 'include' }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  });
};

export const patchVehicle = (vehicle_hash, params) => {
  const url = `${API_HOST_V2}/vehicles/${vehicle_hash}`;
  return fetch(url, {
    method: 'PATCH',
    body: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/JSON',
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.json().then((json) => {
        throw json;
      });
    }
  });
};

export const getPricingPlans = () => {
  const url = `${API_HOST_V2}/pricing-plans`;
  return fetch(url).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.json().then((json) => {
        throw json;
      });
    }
  });
};

export const createPricingPlan = (params) => {
  const url = `${API_HOST_V2}/pricing-plans`;
  return fetch(url, {
    method: 'POST',
    body: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/JSON',
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.json().then((json) => {
        throw json;
      });
    }
  });
};

export const getVehicleRides = (vehicle_id) => {
  const url = `${API_HOST_V2}/vehicles/${vehicle_id}/rides`;
  return fetch(url, { credentials: 'include' }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.json().then((json) => {
        throw json;
      });
    }
  });
};

export const getRidesCSV = (start, end, type) => {
  const url = `${API_HOST_V2}/admins/rides-csv/${start}/${end}/${type}`;
  return fetch(url, { credentials: 'include' }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.json().then((json) => {
        throw json;
      });
    }
  });
};

export const getRidesInZone = (zone_id, params) => {
  var queryString = Object.keys(params)
    .map((key) => key + '=' + params[key])
    .join('&');
  const url = `${API_HOST_V2}/zones/${zone_id}/rides?${queryString}`;
  return fetch(url, { credentials: 'include' }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.json().then((json) => {
        throw json;
      });
    }
  });
};

export const getRideById = (params) => {
  var queryString = Object.keys(params)
    .map((key) => key + '=' + params[key])
    .join('&');
  const url = `${API_HOST_V2}/rides?${queryString}`;
  return fetch(url, { credentials: 'include' }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.json().then((json) => {
        throw json;
      });
    }
  });
};

export const getRestrictedAreas = (zone_id) => {
  const url = `${API_HOST_V3}/zones/${zone_id}/restricted-areas/`;
  return fetch(url, { credentials: 'include' }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.json().then((json) => {
        throw json;
      });
    }
  });
};

export const getRestrictedAreasWithParams = (zone_id, params) => {
  var queryString = Object.keys(params)
    .map((key) => key + '=' + params[key])
    .join('&');
  const url = `${API_HOST_V3}/zones/${zone_id}/restricted-areas?${queryString}`;
  return fetch(url, { credentials: 'include' }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.json().then((json) => {
        throw json;
      });
    }
  });
};

export const getRoutes = (params) => {
  var queryString = Object.keys(params)
    .map((key) => key + '=' + params[key])
    .join('&');
  const url = `${API_HOST_V2}/routes?${queryString}`;
  return fetch(url, { credentials: 'include' }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.json().then((json) => {
        throw json;
      });
    }
  });
};

export const getManualRoute = (params) => {
  var queryString = Object.keys(params)
    .map((key) => key + '=' + params[key])
    .join('&');
  const url = `${API_HOST_V2}/routes?${queryString}`;
  return fetch(url, { credentials: 'include' }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.json().then((json) => {
        throw json;
      });
    }
  });
};

export const getRoute = (route_id) => {
  const url = `${API_HOST_V2}/routes/${route_id}`;
  return fetch(url, { credentials: 'include' }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.json().then((json) => {
        throw json;
      });
    }
  });
};

export const getFieldRoutes = (params) => {
  var queryString = Object.keys(params)
    .map((key) => key + '=' + params[key])
    .join('&');
  const url = `${API_HOST_V2}/field-routes?${queryString}`;
  return fetch(url, { credentials: 'include' }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.json().then((json) => {
        throw json;
      });
    }
  });
};

export const getOpsUsers = (zone_id) => {
  const url = `${API_HOST_V2}/zones/${zone_id}/ops-users`;
  return fetch(url, { credentials: 'include' }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.json().then((json) => {
        throw json;
      });
    }
  });
};

export const getFieldRouteById = (field_route_id) => {
  const url = `${API_HOST_V2}/field-routes/route/${field_route_id}`;
  return fetch(url, { credentials: 'include' }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.json().then((json) => {
        throw json;
      });
    }
  });
};

export const getFieldRouteStatuses = (route_id) => {
  const url = `${API_HOST_V2}/field-routes/${route_id}/statuses`;
  return fetch(url, { credentials: 'include' }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.json().then((json) => {
        throw json;
      });
    }
  });
};

export const getFieldRouteTickets = (route_id) => {
  const url = `${API_HOST_V2}/field-routes/${route_id}`;
  return fetch(url, { credentials: 'include' }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.json().then((json) => {
        throw json;
      });
    }
  });
};

export const getVehicleRouteTicket = (hash) => {
  const url = `${API_HOST_V2}/vehicles/${hash}/active-field-route-ticket`;
  return fetch(url, { credentials: 'include' }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.json().then((json) => {
        throw json;
      });
    }
  });
};

export const deleteFieldRoutes = (zone_id) => {
  const url = `${API_HOST_V2}/zones/${zone_id}/field-routes`;
  return fetch(url, {
    method: 'DELETE',
    credentials: 'include',
  }).then((response) => {
    if (response.ok) {
      return response;
    } else {
      return response.json().then((json) => {
        throw json;
      });
    }
  });
};

export const deleteFieldRoute = (route_id) => {
  const url = `${API_HOST_V2}/field-routes/${route_id}`;
  return fetch(url, {
    method: 'DELETE',
    credentials: 'include',
  }).then((response) => {
    if (response.ok) {
      return;
    } else {
      return response.json().then((json) => {
        throw json;
      });
    }
  });
};

export const deleteFieldRouteTicket = (ticket_id) => {
  const url = `${API_HOST_V2}/field-route-tickets/${ticket_id}`;
  return fetch(url, {
    method: 'DELETE',
    credentials: 'include',
  }).then((response) => {
    if (response.ok) {
      return response;
    } else {
      return response.json().then((json) => {
        throw json;
      });
    }
  });
};

export const createFieldTickets = (params) => {
  const url = `${API_HOST_V2}/routes/field-tickets`;
  return fetch(url, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/JSON',
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.json().then((json) => {
        throw json;
      });
    }
  });
};

export const createRestrictedArea = (zone_id, params) => {
  const url = `${API_HOST_V2}/zones/${zone_id}/restricted-areas`;
  return fetch(url, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/JSON',
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.json().then((json) => {
        throw json;
      });
    }
  });
};

export const patchRestrictedArea = (zone_id, restricted_area_id, params) => {
  const url = `${API_HOST_V2}/zones/${zone_id}/restricted-areas/${restricted_area_id}`;
  return fetch(url, {
    method: 'PATCH',
    credentials: 'include',
    body: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/JSON',
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.json().then((json) => {
        throw json;
      });
    }
  });
};

export const getRidesLocations = (params) => {
  var queryString = Object.keys(params)
    .map((key) => key + '=' + params[key])
    .join('&');
  const url = `${API_HOST_V2}/rides/rides-locations?${queryString}`;
  return fetch(url, { credentials: 'include' }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.json().then((json) => {
        throw json;
      });
    }
  });
};

export const getRideLocations = (ride_id) => {
  const url = `${API_HOST_V2}/rides/${ride_id}/locations`;
  return fetch(url, { credentials: 'include' }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.json().then((json) => {
        throw json;
      });
    }
  });
};

export const getRefunds = (user_id) => {
  const path = `/users/${user_id}/refunds`;
  const url = `${API_HOST_V2}${path}`;
  return fetch(url, { credentials: 'include' })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
};

export const patchFieldRoute = (field_route_id, params) => {
  const url = `${API_HOST_V2}/field-routes/${field_route_id}`;
  return fetch(url, {
    credentials: 'include',
    method: 'PATCH',
    body: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/JSON',
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw new Error('There was a problem trying to update the user');
    }
  });
};

export const createFieldRoute = (params) => {
  const url = `${API_HOST_V2}/field-routes`;
  return fetch(url, {
    credentials: 'include',
    method: 'POST',
    body: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/JSON',
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw new Error('There was a problem trying to create a new route');
    }
  });
};

export const createScheduledRoute = (params) => {
  const url = `${API_HOST_V2}/scheduled-routes`;
  return fetch(url, {
    credentials: 'include',
    method: 'POST',
    body: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/JSON',
    },
  }).then((response) => {
    if (response.ok) {
      return;
    } else {
      throw new Error('There was a problem trying to create a new route');
    }
  });
};

export const getRoutedOps = (zone_id) => {
  const url = `${API_HOST_V2}/zones/${zone_id}/routed-ops`;
  return fetch(url, { credentials: 'include' }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.json().then((json) => {
        throw json;
      });
    }
  });
};

export const getScheduledRoutes = (zone_id) => {
  const url = `${API_HOST_V2}/zones/${zone_id}/scheduled-routes`;
  return fetch(url, { credentials: 'include' }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.json().then((json) => {
        throw json;
      });
    }
  });
};

export const deleteScheduledRoute = (scheduled_route_id) => {
  const url = `${API_HOST_V2}/scheduled-routes/${scheduled_route_id}`;
  return fetch(url, {
    method: 'DELETE',
    credentials: 'include',
  }).then((response) => {
    if (response.ok) {
      return;
    } else {
      return response.json().then((json) => {
        throw json;
      });
    }
  });
};

export const deleteRestrictedArea = (zone_id, restricted_area_id) => {
  const url = `${API_HOST_V2}/zones/${zone_id}/restricted-areas/${restricted_area_id}`;
  return fetch(url, {
    method: 'DELETE',
    credentials: 'include',
  }).then((response) => {
    return response.json();
  });
};

export const createVehicleDamage = (params) => {
  const url = `${API_HOST_V2}/damage-tickets`;
  return fetch(url, {
    credentials: 'include',
    method: 'POST',
    body: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/JSON',
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw new Error('There was a problem trying to create damage on vehicle');
    }
  });
};

export const submitFirmwareUpdateRequests = (vehicles, dryRun = true) => {
  const url = `${API_HOST_V2}/vehicles/firmware-updates`;
  const body = JSON.stringify({ dryRun, names: vehicles });
  return fetch(url, {
    credentials: 'include',
    method: 'POST',
    body,
    headers: {
      'Content-Type': 'application/JSON',
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw new Error('There was a problem with the request');
    }
  });
};

export const getFirmwareJobs = (from = new Date()) => {
  const url = `${API_HOST_V2}/vehicles/firmware-updates?start_time=${from.toISOString()})}`;
  return fetch(url, {
    credentials: 'include',
    method: 'GET',
  }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw new Error('There was a problem with the request');
    }
  });
};

export const getRidePhoto = (ride_id, photo_id) => {
  const url = `${API_HOST_V2}/rides/${ride_id}/photos/${photo_id}`;
  return fetch(url, { credentials: 'include' }).then((response) => {
    if (response.statusText === 'OK') {
      return response.url;
    } else {
      return response.statusText;
    }
  });
};

export const getSubzones = (zone_id) => {
  const url = `${API_HOST_V3}/zones/${zone_id}/subzones`;
  return fetch(url, { credentials: 'include' }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.statusText;
    }
  });
};

export const getFeatures = (zone_id, params) => {
  let url;
  if (params === undefined) {
    url = `${API_HOST_V3}/zones/${zone_id}/features`;
  } else {
    var queryString = Object.keys(params)
      .map((key) => key + '=' + params[key])
      .join('&');
    url = `${API_HOST_V3}/zones/${zone_id}/features?${queryString}`;
  }
  return fetch(url, { credentials: 'include' }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.statusText;
    }
  });
};

export const getDeploymentSpots = (zone_id) => {
  const url = `${API_HOST_V2}/zones/${zone_id}/deployment-locations`;
  return fetch(url, { credentials: 'include' }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.statusText;
    }
  });
};

export const getParkingSpot = (zone_id, spot_id) => {
  const url = `${API_HOST_V3}/zones/${zone_id}/parking-spots/${spot_id}`;
  return fetch(url, { credentials: 'include' }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.statusText;
    }
  });
};

export const sendPhotoReview = (ride_id, photo_id, params) => {
  const url = `${API_HOST_V2}/rides/${ride_id}/photos/${photo_id}/photo-review`;
  return fetch(url, {
    credentials: 'include',
    method: 'POST',
    body: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/JSON',
    },
  }).then((response) => {
    if (response.ok) {
      return;
    } else {
      return response.json((json) => {
        console.log(json);
        throw new Error('There was a problem trying to create photo review');
      });
    }
  });
};

export const vehicleWriteoff = (vehicle_hash, params) => {
  const url = `${API_HOST_V2}/vehicles/${vehicle_hash}/writeoff-recover/`;
  return fetch(url, {
    credentials: 'include',
    method: 'POST',
    body: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/JSON',
    },
  }).then((response) => {
    if (response.ok) {
      return;
    } else {
      throw new Error('There was a problem trying to create photo review');
    }
  });
};

export const getBattery = (bar_code) => {
  const url = `${API_HOST_V2}/batteries/${bar_code}`;
  return fetch(url, { credentials: 'include' }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw new Error('There was an error getting the user id associated with the admin account');
    }
  });
};

export const writeOffOrRecoverBattery = (bar_code, params) => {
  const url = `${API_HOST_V2}/batteries/${bar_code}/writeoff-recover/`;
  return fetch(url, {
    credentials: 'include',
    method: 'POST',
    body: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/JSON',
    },
  }).then((response) => {
    if (response.ok) {
      return;
    } else {
      throw new Error('There was a problem trying to create photo review');
    }
  });
};

export const getRouteVehicles = (params) => {
  var queryString = Object.keys(params)
    .map((key) => key + '=' + params[key])
    .join('&');
  const url = `${API_HOST_V2}/routes/vehicles?${queryString}`;
  return fetch(url, { credentials: 'include' }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.json().then((json) => {
        throw json;
      });
    }
  });
};
export const getOpsLocations = (zone_id, params) => {
  var queryString = Object.keys(params)
    .map((key) => key + '=' + params[key])
    .join('&');
  const url = `${API_HOST_V2}/zones/${zone_id}/ops-locations?${queryString}`;
  return fetch(url, { credentials: 'include' }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.json().then((json) => {
        throw json;
      });
    }
  });
};

export const getVehiclesInPolygon = (zone_id, params) => {
  var queryString = Object.keys(params)
    .map((key) => {
      let statuses;
      if (key === 'statuses') {
        statuses = params[key]
          .map((val) => {
            const status = 'statuses[]=' + val;
            return status;
          })
          .join('&');
        return statuses;
      } else {
        const query_val = key + '=' + params[key];
        return query_val;
      }
    })
    .join('&');
  const url = `${API_HOST_V2}/zones/${zone_id}/vehicles?${queryString}`;
  return fetch(url, { credentials: 'include' })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .catch((error) => {
      throw error;
    });
};

export const getLastRideForFine = (params) => {
  var queryString = Object.keys(params)
    .map((key) => key + '=' + params[key])
    .join('&');
  const url = `${API_HOST_V3}/fines/find-ride?${queryString}`;
  return fetch(url, { credentials: 'include' }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      switch (response.status) {
        case 404:
          throw new Error('Ride not found');
        default:
          throw new Error(
            'There was a problem with your request. Check to make sure both Vehicle Name and Date are entered'
          );
      }
    }
  });
};

export const getVehiclesInArea = (zone_id, params) => {
  var queryString = Object.keys(params)
    .map((key) => {
      let statuses;
      if (key === 'statuses') {
        statuses = params[key]
          .map((val) => {
            const status = 'statuses[]=' + val;
            return status;
          })
          .join('&');
        return statuses;
      } else {
        const query_val = key + '=' + params[key];
        return query_val;
      }
    })
    .join('&');
  const url = `${API_HOST_V2}/zones/${zone_id}/vehicles?${queryString}`;

  return fetch(url, { credentials: 'include' })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
};

export const createParkingSpot = (zone_id, params) => {
  const url = `${API_HOST_V3}/zones/${zone_id}/parking-spots/`;
  return fetch(url, {
    method: 'POST',
    body: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/JSON',
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.json().then((json) => {
        throw json;
      });
    }
  });
};

export const patchParkingSpot = (zone_id, parking_spot_id, params) => {
  const url = `${API_HOST_V3}/zones/${zone_id}/parking-spots/${parking_spot_id}`;
  return fetch(url, {
    method: 'PATCH',
    credentials: 'include',
    body: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/JSON',
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.json().then((json) => {
        throw json;
      });
    }
  });
};

export const deleteParkingSpot = (zone_id, parking_spot_id) => {
  const url = `${API_HOST_V3}/zones/${zone_id}/parking-spots/${parking_spot_id}`;
  return fetch(url, {
    method: 'DELETE',
    credentials: 'include',
  }).then((response) => {
    if (response.ok) {
      return;
    } else {
      return response.json().then((json) => {
        throw json;
      });
    }
  });
};

export const parkingSpotCapacities = (zoneId) => {
  const url = `${API_HOST_V2}/zones/${zoneId}/parking-spots/vehicle-counts`;
  return fetch(url, { credentials: 'include' }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.json().then((json) => {
        throw json;
      });
    }
  });
};

export const clearUnpaidRideBalance = (rideId) => {
  const url = `${API_HOST_V2}/rides/${rideId}/clear-balance`;
  return fetch(url, { credentials: 'include', method: 'DELETE' }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.json().then((json) => {
        throw json;
      });
    }
  });
};

export const getSafetyReports = ({
  reportTypes = [],
  employee,
  startDate,
  resolutions = [],
  zoneId,
}) => {
  const baseUrl = `${API_HOST_V2}/safety-reports`;
  const queryParams = new URLSearchParams();
  employee && queryParams.append('employee', employee);
  startDate && queryParams.append('start_date', startDate.toISOString());
  zoneId && queryParams.append('zone_id', zoneId);
  reportTypes.forEach((rt) => queryParams.append('report_type', rt));
  resolutions.forEach((rt) => queryParams.append('resolution', rt));
  const url = `${baseUrl}?${queryParams.toString()}`;
  return fetch(url, { credentials: 'include', method: 'GET' }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.json().then((json) => {
        throw json;
      });
    }
  });
};

export const getSafetyReportDocumentUrl = ({ safetyReportId, documentId }) => {
  const url = `${API_HOST_V2}/safety-reports/${safetyReportId}/documents/${documentId}`;
  return fetch(url, { credentials: 'include', method: 'GET' }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.json().then((json) => {
        throw json;
      });
    }
  });
};

export const postSafetyReport = ({
  employee,
  accidentPacket,
  incidentDate,
  incidentTime,
  reportType,
  resolution,
  zoneId,
}) => {
  const baseUrl = `${API_HOST_V2}/safety-reports`;
  var formData = new FormData();
  formData.append('incident_date', incidentDate);
  formData.append('incident_time', incidentTime);
  formData.append('report_type', reportType);
  formData.append('resolution', resolution);
  formData.append('user_id', employee);
  formData.append('zone_id', zoneId);
  accidentPacket.forEach((file) => formData.append('files', file, file.name));
  return fetch(baseUrl, {
    body: formData,
    credentials: 'include',
    method: 'POST',
  }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.json().then((json) => {
        throw json;
      });
    }
  });
};

export const attachSafetyReportDocuments = ({ id, files }) => {
  const baseUrl = `${API_HOST_V2}/safety-reports/${id}/documents`;
  var formData = new FormData();
  files.forEach((file) => formData.append('files', file, file.name));
  return fetch(baseUrl, {
    body: formData,
    credentials: 'include',
    method: 'POST',
  }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.json().then((json) => {
        throw json;
      });
    }
  });
};

export const deleteSafetyReportDocument = ({ safetyReportId, documentId }) => {
  const url = `${API_HOST_V2}/safety-reports/${safetyReportId}/documents/${documentId}`;
  return fetch(url, { credentials: 'include', method: 'DELETE' }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.json().then((json) => {
        throw json;
      });
    }
  });
};

export const patchSafetyReport = ({ id, resolution }) => {
  const url = `${API_HOST_V2}/safety-reports/${id}`;
  const body = JSON.stringify({ resolution });
  return fetch(url, {
    credentials: 'include',
    method: 'PATCH',
    body,
    headers: {
      'Content-Type': 'application/JSON',
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.json().then((json) => {
        throw json;
      });
    }
  });
};
