import * as api from '../api';
import { PURGE } from 'redux-persist';

const GET_VEHICLE_RIDES = 'vehicle_rides/GET_VEHICLE_RIDES';
const CLEAR_VEHICLE_RIDES = 'vehicle_rides/CLEAR_VEHICLE_RIDES';

const initialState = [];

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_VEHICLE_RIDES:
      return action.data;
    case CLEAR_VEHICLE_RIDES:
      return [];
    case PURGE:
      return [];
    default:
      return state;
  }
}

export const getVehicleRides = vehicle_id => {
  return dispatch => {
    return api.getVehicleRides(vehicle_id).then(rides => {
      dispatch({ type: GET_VEHICLE_RIDES, data: rides });
    });
  };
};

export const clearVehicleRides = () => {
  return dispatch => {
    dispatch({ type: CLEAR_VEHICLE_RIDES });
  };
};
