import * as api from '../api';
import { PURGE } from 'redux-persist';

const GET_VEHICLE_TYPES = 'vehicles/GET_VEHICLE_TYPES';

const initialState = [];

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_VEHICLE_TYPES:
      return action.data;
    case PURGE:
      return [];
    default:
      return state;
  }
}

export const getVehicleTypes = () => {
  return dispatch => {
    return api.getVehicleTypes().then(types => {
      dispatch({ type: GET_VEHICLE_TYPES, data: types });
    });
  };
};
