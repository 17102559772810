import * as api from '../api';
import { PURGE } from 'redux-persist';

const GET_VEHICLE = 'vehicles/GET_VEHICLE';
const GET_VEHICLE_ROUTE_TICKET = 'vehicles/GET_VEHICLE_ROUTE_TICKET';
const CLEAR_VEHICLE = 'vehicles/CLEAR_VEHICLE';
const CREATE_VEHICLE_DAMAGE = 'vehicles/CREATE_VEHICLE_DAMAGE';
const WRITEOFF = 'vehicles/WRITEOFF';

const initialState = null;

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_VEHICLE:
      return action.data;
    case PURGE:
      return initialState;
    case WRITEOFF:
      return state;
    case CLEAR_VEHICLE:
      return initialState;
    case CREATE_VEHICLE_DAMAGE:
      return action.data;
    case GET_VEHICLE_ROUTE_TICKET:
      return {
        ...state,
        active_route_ticket: action.data,
      };
    default:
      return state;
  }
}

export const getVehicleByName = (name) => {
  return (dispatch) => {
    return api
      .getVehicleByName(name)
      .then((result) => {
        if (result) {
          dispatch({ type: GET_VEHICLE, data: result.vehicle });
        } else {
          throw new Error('There was a problem trying to get vehicle');
        }
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const getActiveVehicleRouteTicket = (hash) => {
  return (dispatch) => {
    return api
      .getVehicleRouteTicket(hash)
      .then((result) => {
        if (result) {
          dispatch({ type: GET_VEHICLE_ROUTE_TICKET, data: result });
        } else {
          throw new Error('There was a problem trying to get vehicle ticket');
        }
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const patchVehicle = (vehicleHash, params) => {
  return (dispatch) => {
    return api
      .patchVehicle(vehicleHash, params)
      .then((vehicle) => dispatch({ type: GET_VEHICLE, data: vehicle }));
  };
};

export const createVehicleDamage = (params) => {
  return (dispatch) => {
    return api
      .createVehicleDamage(params)
      .then((vehicle) => dispatch({ type: CREATE_VEHICLE_DAMAGE, data: vehicle }));
  };
};

export const writeoff = (vehicle_hash, params) => {
  return (dispatch) => {
    return api.vehicleWriteoff(vehicle_hash, params).then(() => dispatch({ type: WRITEOFF }));
  };
};

export const clearVehicle = () => (dispatch) => dispatch({ type: CLEAR_VEHICLE });
