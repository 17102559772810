import * as api from '../api';

const GET_WAREHOUSE = 'zones/GET_WAREHOUSE';

const initialState = { warehouse: [] };

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_WAREHOUSE:
      return action.data;
    default:
      return state;
  }
}

export const getWarehouse = (zone_id) => {
  return (dispatch) => {
    return api
      .getWarehouse(zone_id)
      .then((warehouse) => {
        if (warehouse) {
          dispatch({ type: GET_WAREHOUSE, data: warehouse });
        } else {
          throw new Error('There was a problem trying to get zone data');
        }
      })
      .catch((error) => {
        throw error;
      });
  };
};
