import * as api from '../api';

const ADMIN_LOGIN = 'user/USER_LOGIN';
const ADMIN_LOGOUT = 'user/USER_LOGOUT';
const OPS_ID = 'user/OPS_ID';

const initialState = null;

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ADMIN_LOGIN: {
      const admin = action.data;
      return admin;
    }
    case ADMIN_LOGOUT:
      return null;
    case OPS_ID:
      return {
        ...state,
        ops_id: action.data,
      };
    default:
      return state;
  }
}

export const me = () => {
  return (dispatch) => {
    return api
      .me()
      .then((admin) => {
        if (admin) {
          dispatch({ type: ADMIN_LOGIN, data: admin });
        }
      })
      .catch(() => {
        // Errors here are typically due to session expiry, but
        // whatever the cause, just log out the admin.
        dispatch({ type: ADMIN_LOGOUT });
      });
  };
};

export const login = (email, password) => {
  return (dispatch) => {
    return api
      .login(email, password)
      .then((admin) => {
        if (admin) {
          dispatch({ type: ADMIN_LOGIN, data: admin });
        }
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const logout = () => {
  return (dispatch) => {
    return api
      .logout()
      .then(() => {
        // Ignore success/failure on server, logout on the client side
        dispatch({ type: ADMIN_LOGOUT });
      })
      .catch(() => {
        // Absorb the error and logout on the client side anyway
        dispatch({ type: ADMIN_LOGOUT });
      });
  };
};
