import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import logger from 'redux-logger';

import reducers from './state';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['admin', 'vehicle_firmware', 'zones'],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const middleware =
  process.env.NODE_ENV === 'development' ? applyMiddleware(thunk, logger) : applyMiddleware(thunk);

// createStore gets called twice (I think due to SSR), so we need to make sure that persistor
// is initialized only once, because otherwise the Redux store gets persisted/rehydrated
// multiple times (e.g. when reloading a page), and the Redux state is lost.
let persistor;
let store;

export default (preloadedState = {}) => {
  if (!store) {
    store = createStore(persistedReducer, preloadedState, middleware);
  }
  if (!persistor) {
    persistor = persistStore(store);
  }
  return { store, persistor };
};
