import * as api from '../api';

const GET_PRICING_PLANS = 'pricingPlans/GET';

const initialState = [];

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_PRICING_PLANS:
      return action.data;
    default:
      return state;
  }
}

export const getPricingPlans = () => {
  return (dispatch) => {
    return api
      .getPricingPlans()
      .then((plans) => {
        if (plans) {
          dispatch({ type: GET_PRICING_PLANS, data: plans });
        }
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const createPricingPlan = (params) => {
  return () => {
    return api
      .createPricingPlan(params)
      .then((response) => response)
      .catch(() => {});
  };
};
