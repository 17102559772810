import * as api from '../api';
import { PURGE, REHYDRATE } from 'redux-persist';
const moment = require('moment');

const CLEAR_JOBS = 'vehicle_firmware/CLEAR_JOBS';
const GET_JOBS = 'vehicle_firmware/GET_JOBS';
const SUBMIT_JOBS = 'vehicle_firmware/SUBMIT_JOBS';

const initialState = {
  startedAt: new Date(),
  vehicles: [],
  jobs: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case REHYDRATE: {
      const { payload: { vehicle_firmware } = {} } = action;
      const startedAt = vehicle_firmware && new Date(vehicle_firmware.startedAt);
      return Object.assign({}, { ...vehicle_firmware }, { startedAt });
    }
    case GET_JOBS:
      return Object.assign({}, { ...state }, { jobs: action.data.jobs });
    case SUBMIT_JOBS:
      return Object.assign({}, { ...state }, { ...action.data });
    case PURGE:
    case CLEAR_JOBS:
      return initialState;
    default:
      return state;
  }
}

export const submitJobs = (vehicles, dryRun) => {
  // Use a starting time stamp from before the API call, to account for the potential of
  // a few seconds' difference between the local and remote
  const startedAt = moment().subtract(1, 'minutes').toDate();
  return (dispatch) => {
    return api.submitFirmwareUpdateRequests(vehicles, dryRun).then((response) => {
      const vehiclesBeingUpdated = [];
      response.vehicleData.forEach((batch) => vehiclesBeingUpdated.push(...batch.vehicles));
      const data = {
        startedAt,
        vehicles: vehiclesBeingUpdated,
      };
      dispatch({ type: SUBMIT_JOBS, data });
    });
  };
};

export const searchJobs = (vehicles, startedAt) => {
  return (dispatch) => {
    const data = {
      startedAt,
      vehicles,
    };
    dispatch({ type: SUBMIT_JOBS, data });
  };
};

export const getJobs = (from) => {
  return (dispatch) => {
    return api.getFirmwareJobs(from).then((jobs) => {
      dispatch({ type: GET_JOBS, data: jobs });
    });
  };
};

export const clearJobs = () => (dispatch) => dispatch({ type: CLEAR_JOBS });
