import * as api from '../api';

const GET_FIELD_ROUTE_TICKETS = 'routes/GET_FIELD_ROUTE_TICKETS';
const DELETE_FIELD_ROUTE_TICKET = 'routes/DELETE_FIELD_ROUTE_TICKETS';

const initialState = [];

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_FIELD_ROUTE_TICKETS:
      return action.data;
    case DELETE_FIELD_ROUTE_TICKET:
      return null;
    default:
      return state;
  }
}

export const getFieldRouteTickets = (route_id) => {
  return (dispatch) => {
    return api
      .getFieldRouteTickets(route_id)
      .then((routeTickets) => {
        dispatch({ type: GET_FIELD_ROUTE_TICKETS, data: routeTickets });
      })
      .catch((error) => {
        alert(error.message);
        throw new Error('Get Field Route statuses failed');
      });
  };
};

export const deleteFieldRouteTicket = (ticket_id) => {
  return (dispatch) => {
    return api
      .deleteFieldRouteTicket(ticket_id)
      .then(() => dispatch({ type: DELETE_FIELD_ROUTE_TICKET }))
      .catch((error) => {
        alert(error.message);
        throw new Error('Get Field Route statuses failed');
      });
  };
};
