import * as api from '../api';
import { PURGE } from 'redux-persist';

const GET_SAFETY_REPORTS = 'safety_reports/GET_SAFETY_REPORTS';

const initialState = [];

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_SAFETY_REPORTS:
      return action.data;
    case PURGE:
      return initialState;
    default:
      return state;
  }
}

export const getSafetyReports = (params) => {
  return (dispatch) => {
    return api.getSafetyReports(params).then((reports) => {
      dispatch({ type: GET_SAFETY_REPORTS, data: reports });
    });
  };
};
