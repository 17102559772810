import * as api from '../api';

const GET_FINE = 'zones/GET_FINE';

const initialState = [];

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_FINE:
      return action.data;
    default:
      return state;
  }
}

export const getLastRideForFine = params => {
  return dispatch => {
    return api
      .getLastRideForFine(params)
      .then(fine => {
        if (fine) {
          dispatch({ type: GET_FINE, data: fine });
        } else {
          throw new Error('There was a problem trying to fine');
        }
      })
      .catch(error => {
        throw error;
      });
  };
};