import { combineReducers } from 'redux';
import admin from './admin';
import battery from './battery';
import battery_types from './battery_types';
import comm_types from './comm_types';
import field_routes from './field_routes';
import field_route_tickets from './field_route_tickets';
import field_route_tickets_statuses from './field_route_tickets_statuses';
import fine from './fine';
import ops_locations from './ops_locations';
import parking_spots from './parking_spots';
import pricingPlans from './pricingPlans';
import refunds from './refunds';
import restricted_areas from './restricted_areas';
import rides from './rides';
import ride_photos from './ride_photos';
import routes from './routes';
import safety_reports from './safety_reports';
import scheduled_routes from './scheduled_routes';
import selected_zone from './selected_zone';
import subzones from './subzones';
import vehicle from './vehicle';
import vehicle_firmware from './vehicle_firmware';
import vehicle_rides from './vehicle_rides';
import vehicle_statuses from './vehicle_statuses';
import vehicle_types from './vehicle_types';
import user from './user';
import users from './users';
import vehicles from './vehicles';
import warehouse from './warehouse';
import zones from './zones';
import zone_rides from './zone_rides';

const rootReducer = combineReducers({
  admin,
  battery,
  battery_types,
  comm_types,
  field_routes,
  field_route_tickets,
  field_route_tickets_statuses,
  fine,
  ops_locations,
  parking_spots,
  pricingPlans,
  refunds,
  restricted_areas,
  rides,
  ride_photos,
  routes,
  safety_reports,
  scheduled_routes,
  selected_zone,
  subzones,
  user,
  users,
  vehicle,
  vehicles,
  vehicle_firmware,
  vehicle_rides,
  vehicle_statuses,
  vehicle_types,
  warehouse,
  zones,
  zone_rides,
});

export default rootReducer;
