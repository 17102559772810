import * as api from '../api';
import { PURGE } from 'redux-persist';

const GET_BATTERY = 'battery/GET_BATTERY';
const WRITE_OFF_OR_RECOVER = 'battery/WRITE_OFF_OR_RECOVER';
const CLEAR_BATTERY = 'battery/CLEAR_BATTERY';

const initialState = [];

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_BATTERY:
      return action.data;
    case WRITE_OFF_OR_RECOVER:
      return state;
    case CLEAR_BATTERY:
      return [];
    case PURGE:
      return [];
    default:
      return state;
  }
}

export const getBattery = (bar_code) => {
  return (dispatch) => {
    return api.getBattery(bar_code).then((types) => {
      dispatch({ type: GET_BATTERY, data: types });
    });
  };
};

export const writeOffOrRecoverBattery = (bar_code, params) => {
  return () => {
    return api.writeOffOrRecoverBattery(bar_code, params).then((response) => response);
  };
};

export const clearBattery = () => (dispatch) => dispatch({ type: CLEAR_BATTERY });
