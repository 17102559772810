import * as api from '../api';

const GET_SCHEDULED_ROUTES = 'routes/GET_SCHEDULED_ROUTES';
const DELETE_SCHEDULED_ROUTE = 'routes/DELETE_SCHEDULED_ROUTE';

const initialState = [];

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_SCHEDULED_ROUTES:
      return action.data;
    case DELETE_SCHEDULED_ROUTE:
      return state;
    default:
      return state;
  }
}

export const getScheduledRoutes = (zone_id) => {
  return (dispatch) => {
    return api
      .getScheduledRoutes(zone_id)
      .then((route) => {
        dispatch({ type: GET_SCHEDULED_ROUTES, data: route });
      })
      .catch((error) => {
        alert(error.message);
        throw new Error('Get Route failed');
      });
  };
};

export const deleteScheduledRoute = (scheduled_route_id) => {
  return (dispatch) => {
    return api
      .deleteScheduledRoute(scheduled_route_id)
      .then(() => dispatch({ type: DELETE_SCHEDULED_ROUTE, data: null }))
      .catch(() => {
        throw new Error('delete Field Routes failed');
      });
  };
};
