import * as api from '../api';

const CREATE_FIELD_ROUTE = 'routes/CREATE_ROUTE';
const GET_FIELD_ROUTES = 'routes/GET_FIELD_ROUTES';
const CANCEL_FIELD_ROUTE = 'routes/CANCEL_FIELD_ROUTE';
const DELETE_FIELD_ROUTE = 'routes/DELETE_FIELD_ROUTE';
const GET_ROUTE_BY_FIELD_ROUTE = 'routes/GET_ROUTE_BY_FIELD_ROUTE';

const initialState = [];

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_FIELD_ROUTES:
      return action.data;
    case CANCEL_FIELD_ROUTE:
      return state;
    case CREATE_FIELD_ROUTE:
      return {
        ...state,
        selected_route: action.data,
      };
    case DELETE_FIELD_ROUTE:
      return state;
    case GET_ROUTE_BY_FIELD_ROUTE:
      return action.data;
    default:
      return state;
  }
}

export const createFieldRoute = (params) => {
  return (dispatch) => {
    return api
      .createFieldRoute(params)
      .then((route) => dispatch({ type: CREATE_FIELD_ROUTE, data: route }))
      .catch(() => {
        throw new Error('Create Route failed');
      });
  };
};

export const getFieldRoutes = (params) => {
  return (dispatch) => {
    return api
      .getFieldRoutes(params)
      .then((routes) => dispatch({ type: GET_FIELD_ROUTES, data: routes }))
      .catch(() => {
        throw new Error('Get Field Routes failed');
      });
  };
};

export const cancelFieldRoute = (params, route_id) => {
  return (dispatch) => {
    return api
      .patchFieldRoute(route_id, params)
      .then(() => dispatch({ type: CANCEL_FIELD_ROUTE, data: null }))
      .catch(() => {
        throw new Error('Cancel Field Routes failed');
      });
  };
};

export const deleteFieldRoute = (route_id) => {
  return (dispatch) => {
    return api
      .deleteFieldRoute(route_id)
      .then(() => dispatch({ type: DELETE_FIELD_ROUTE, data: null }))
      .catch(() => {
        throw new Error('delete Field Routes failed');
      });
  };
};

export const getFieldRouteById = (field_route_id) => {
  return (dispatch) => {
    return api
      .getFieldRouteById(field_route_id)
      .then((route) => dispatch({ type: GET_ROUTE_BY_FIELD_ROUTE, data: route }))
      .catch(() => {
        throw new Error('Get Route By Field Route failed');
      });
  };
};
